.client-card{
    min-height: 85%;
    margin: 5% 0;
    padding: 5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
}

.client-card h4{
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 3%;
}

.client-card p{
    margin: 10% 0 5%;
    font-size: 1rem;
}

.live-button, .github-button{
    margin: 3% 0;
    padding: 2% 5%;
    width: 40%;
    text-align: center;
    display: inline-block;
    border-radius: 2px;
}

a.live-button, a.github-button{
    text-decoration: none;
}


.live-button, .live-button:hover{
    color: white;
    background-color: brown;
}
.github-button, .github-button:hover{
    color: black;
    background-color: white;
    margin-left: 10px;
}

.client-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.client-img{
    width: 100%;
}

@media only screen and (max-width: 991px){
    .client-card{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 10%;
    }

    .client-img{
        width: 50%;
    }

    .client-card h4{
        text-align: center;
        margin-top: 10%;
    }

    .client-card p{
        text-align: center;
        margin-top: 5%;
    }

    .work-links{
        text-align: center;
    }
    
}