.footer {
    margin: 0;
    text-align: center;
    padding: 1% 10%;
    padding-bottom: 1%;
    background-color: rgba(0, 0, 0, 0.581);
    color: white;
    width: 100%;
    overflow: hidden;
}

.foot-link {
    color: #ff7e39;
    font-weight: 700;
}

.footer p{
    margin: 0;
}

.footer li {
    margin-left: 0;
    margin: 10px;
    margin-top: 10px;
    text-decoration: none;
    list-style: none;
}

.footer li:not(.foot-link) {
    display: inline;
}

.footer a {
    margin: 0;
    margin-bottom: 0;
    text-decoration: none;
    color: white;
}

.footer a:hover {
    color: #4d20ff;
}

.footer ul {
    width: 40%;
    margin: 0 auto;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footer p{
    font-size: 1rem;
}

.copy-right {
    width: 100vw;
    transform: translateX(-10%);
    border-radius: 0.5px;
    border-top: 1px solid white;
}

@media only screen and (max-width: 991px){
    
    .footer ul {
        width : 100%;
        padding: 10px;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    
    .footer p{
        margin-bottom: 10px;
    }

    .footer{
        margin-bottom: 20px;
    }
}