/* Loader */
.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
    content: '';  
  box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #FF3D00;
}

.submit-loading{
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitting{
  display: none;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 






.contact {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  padding-top: 7%;
  background-color: black;
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.87),
      rgba(0, 0, 0, 0.87)),
    linear-gradient(-45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
  backdrop-filter: blur(100);
  background-size: 400% 400%;
  animation: gradient 6s ease infinite;
  color: white;
  overflow: hidden;
  text-align: center;
}

.contact h1 {
  text-align: left;
  pointer-events: none;
  font-size: 6rem;
  font-weight: 400;
  margin-left: 10%;
  margin-top: 50px;
}

.contact h2 {
  text-align: left;
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
  margin-top: 10%;
  margin-left: 20.5%;
}

.contact h3 {
  font-size: 2rem;
  text-align: left;
  margin-left: 10%;
  font-weight: 300;
}

.contact h3.light {
  color: rgba(176, 176, 176, 0.726);
}

.contact p {
  font-size: 1.3rem;
  font-weight: 200;
  line-height: 2.5rem;
  text-align: left;
  margin: 0 10%;
  padding: 0;
}

div.contact-mail {
  text-align: left;
  padding: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 12%;
}

h2.contact-mail {
  margin-left: 0;
  margin-bottom: 5%;
  font-weight: 300;

}

.contact-mail p{
  font-size: 1rem;
  margin: 10px 0;
}


.contact-text {
  line-height: 2rem;
}

.contact-text p{
  font-size: 1.1rem;
}

.form-col {
  display: flex;
  flex-direction: column;

  padding: 0;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  margin-left: 20%;
  margin-top: 3%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  color: white;
}


input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  padding-left: 10px;
  color: white;
  border: none;
  /* border: 0px solid #6b6b6b; */
  /* border-radius: 25px; */
  border-bottom: 1px solid #ffffff;
  backdrop-filter: blur(50);
  background-color: transparent;
}

.form-group input[type="text"]::placeholder,
.form-group input[type="email"]::placeholder,
.form-group textarea::placeholder {
  color: rgb(255, 255, 255);
  /* Adjust the opacity here (0.0 to 1.0) */
}

textarea {
  resize: none;
  backdrop-filter: blur(50);
  background-color: transparent;
  overflow-y: hidden;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #ff7429;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 50px;
}

button[type="submit"]:hover {
  background-color: #ff5900;
}

.form-submitted {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.form-submitted p{
  margin-top: -15%;
}

@media only screen and (max-width:991px) {
  .contact {
    text-align: center;
  }

  .contact div {
    padding: 3%;
  }

  .contact h1 {
    font-size: 4rem;
    margin-top: 100px;
  }

  .contact h2 {
    font-size: 1.2rem;
    text-align: center;
  }

  .contact p {
    font-size: 1.2rem;
  }

  .contact-mail p{
    font-size: 1rem;
    margin-left: 5%;
  }

  .contact-mail {
    display: grid;
    line-height: 3rem;
  }


  .form-group {
    margin-bottom: 0px;
  }

  .form-group input, .form-group textarea{
    font-size: 0.9rem;
  }

  .contact h2 {
    margin: auto;
  }

  form {
    margin: auto;
    margin-top: 5%;
    padding: 10%;
  }

  .form-col {
    margin-top: 15%;
    padding: 5%;
  }

  .contact-text {
    margin-bottom: 10%;
  }

  .contact-text p{
    font-size: 1rem;
    line-height: 2rem;
  }
}

button[type="submit"] {
  margin: auto 0 50px;
  border-radius: 25px;
}