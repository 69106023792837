.work {
  text-align: left;
  margin: 0;
  padding: 5% 10% 10%;
  min-height: 100svh;
  background-color: black;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.87),
      rgba(0, 0, 0, 0.87)
    ),
    linear-gradient(-45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
  backdrop-filter: blur(100);
  background-size: 400% 400%;
  animation: gradient 6s ease infinite;
  color: white;
  overflow: hidden;
  position: relative;
}

.work h3{
  margin: 6% 0 1%;
}


@media only screen and (max-width: 991px){


  .work h3{
    text-align: center;
    margin: 30% 0 8%;
    font-size: 3rem;
  }
}
