.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;

  padding: 10%;
  margin: 0;
  background-color: black;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.87),
      rgba(0, 0, 0, 0.87)
    ),
    linear-gradient(-45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
  backdrop-filter: blur(100);
  background-size: 400% 400%;
  animation: gradient 6s ease infinite;
}
