.App {
  text-align: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.content {
  background-color: black;
  color: white;
  min-height: 100svh;
  overflow-x: hidden;
  position: relative;
  text-align: center;
  justify-content: center;
}

/* Home CSS Styles */

.navbar {
  z-index: 100;
  position: fixed;
  width: 100%;
  padding: 5px;
  text-align: center;
  justify-content: center;
  transition: background-color 0.5s ease;
}

.navbar.scrolled{
  background: rgb(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}

.nav-links {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.nav-links li {
  position: relative;
  display: inline-block;
  margin: 7px 40px;
}

.nav-links li p {
  cursor: pointer;
  margin-top: 0;
  font-size: 0.9rem;
  color: #ffffff;
  text-decoration: none;
  padding: 5px;
}

.nav-links li p.active {
  color: #ff7e39;

}

.nav-links li p::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 50%;
  background-color: #ff7e39;
  transition: width 0.3s ease, 0.3s ease;
}

.nav-links li p.active::before {
  width: 50%;
  left: 25%;
  right: auto;
  transform: none;
}

.nav-links li p:hover {
  color: #ff7e39;
}

.section {
  padding: 40px 0;
  text-align: center;
}

.section h2 {
  margin-bottom: 20px;
}

.des-by {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  font-size: 1rem;
}

.name {
  cursor: default;
  display: inline;
  text-decoration: none;
  color: #ff7e39;
}

.name:hover {
  color: #ff6600;
}

.des-by a{
  font-size: 1rem;
}

.des-by p{
  font-size: 1rem;
}

@media only screen and (max-width: 991px) {
  .App{
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .navbar {
    left: 0;
    right:0;
    margin: 0 auto;
    margin-top: 25px;
    height: fit-content;
    width: fit-content;
    border-radius: 40px;
    background: rgba(74, 74, 74, 0.1);
    color: white;
    backdrop-filter: blur(10px);
  }

  .navbar.scrolled {
    background: rgba(74, 74, 74, 0.1);
    backdrop-filter: blur(10px);
  }

  .nav-links {
    height: fit-content;
    width: fit-content;
    overflow: hidden;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .nav-links li {
    margin: 7px 15px;
  }

  .nav-links li p{
    color: rgb(255, 255, 255);
    padding: 0;
    margin: 0;
  }

  .nav-links li p::before {
    height: 0.5px;
  }

  .des-by {
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100%;
    height: 30px;
    padding-top: 3px;
    background-color: black;
  }
}