.home {
  padding: 0;
  margin: 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(../images/wallpaperflare.com_wallpaper.jpg);
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  transition: background-size 0.3s ease;
  object-fit: cover;
  color: white;
  height: 100svh;
  overflow-x: hidden;
  position: relative;
}

.head {
  z-index: 5;
  padding: 0;
  margin: 15% 0 auto 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.name-line {
  z-index: -1;
  position: absolute;
  margin-top: 0;
  margin-left: 2px;
  border: none;
  width: 21.25rem;
  height: 5px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
  opacity: 1;
  transition: transform 1s, height 1s;
  transform-origin: left;
  transform: scaleX(1) translateX(0);
}

h1:hover + .name-line {
  transform: scaleX(1.85) translate(-2.2px);
  background: white;
}

.profile {
  max-width: 100%;
  height: 90vh;
  margin-top: 10vh;
  background-repeat: no-repeat;
  background-size: 80%;
  object-fit: contain;
  overflow: hidden;
}

h1 {
  margin-top: -5px;
  margin-left: -6px;
  padding: 0;
  font-size: 6.8rem;
  line-height: 0.9;
  z-index: 0;
  
}

h1:hover {
  color: transparent;
  background: linear-gradient(45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
  background-size: 400% 400%;
  background-clip: text;
  animation: gradient 8s ease infinite;
}

h3 {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  margin-left: 2px;
  padding: 0;
  font-size: 3rem;
  z-index: 0;
}

p {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  margin-top: 50px;
  padding: 0;
  font-size: 1.2rem;
  z-index: 0;
}

.social-links {
  display: flex;
  justify-content: left;
  margin-top: 60px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4d20ff;
  color: white;
  margin-right: 40px;
  margin-top: 0px;
}

.social-link:hover {
  color: #4d20ff;
  background-color: white;
}

.social-link i {
  font-size: 20px;
}

a {
  text-decoration: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.fade-in {
  opacity: 0;
}

.fade-in-animation {
  opacity: 1;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-in-left {
  animation: slide-in-left 1.5s ease-in-out;
}

@keyframes slide-in-left {
  from {
    transform: translateX(15%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 991px) {
  .home {
    width: 100vw;
    min-height: 800px;
    margin: 0;
    padding: 2%;
    justify-content: center;
    text-align: center;
  }

  .head {
    margin: 0;
    width: 100vw;
    display: flex;
    text-align: center;
    margin-top: 30%;
    margin-left: 0;
    line-height: 3rem;
    overflow-x: hidden;
    justify-content: center;
  }

  .name-line {
    height: 0;
    width: 0;
    pointer-events: none; 
  }

  .head h1 {
    font-size: 6.5rem;
    pointer-events: none;
    color: transparent;
    background: linear-gradient(45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
    background-size: 400% 400%;
    background-clip: text;
    animation: gradient 8s ease infinite;
    margin: 30px auto 40px;
  }

  .head h3 {
    font-weight: 400;
    font-size: 2.5rem;
  }

  .head p {
    font: 1rem;
    margin: 15px auto;
    font-weight: 350;
    width: 75%;
  }

  .social-links {
    justify-content: center;
    margin-right: -40px;
    margin-top: 60px;
  }

  .profile {
    height: 0;
    width: 0;
  }

  .profile-col {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
  
}
