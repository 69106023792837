.about {
  font-family: 'Inter', sans-serif;
  text-align: left;
  padding: 10%;
  margin: 0;
  background-color: black;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.87),
      rgba(0, 0, 0, 0.87)
    ),
    linear-gradient(-45deg, #ee7752, #e73c7e, #1378d7, #37a5dc);
  backdrop-filter: blur(100);
  background-size: 400% 400%;
  animation: gradient 6s ease infinite;
  color: white;
  overflow: hidden;
  position: relative;
}

.about h1 {
  pointer-events: none;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  font-weight: 600;
}

.about h2 {
  font-weight: 200;
}

.about p {
  color: white;
  margin-top: 5rem;
  font-size: 1.2rem;
  font-weight: 240;
  line-height: 2.5rem;
}

.a-big {
  display: inline;
  text-decoration: none;
  color: #ff7e39;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4rem;
}

.a-big:hover {
  color: #7553fe;
}

h1.code-logo {
  font-size: 400px;
  font-weight: 500;
  margin-top: 20%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


@media only screen and (max-width: 991px){
  
  .about {
    padding-top: 30%;
  }

  .about h1 {
    font-size: 4.8rem;
    margin-bottom: 30px;
  }

  .about h2 {
    line-height: 4rem;
  }

  .about p{
    line-height: 3rem;
  }

  h1.code-logo {
    font-size: 0;
  }

  .img-col{
    height: 0;
  }
}